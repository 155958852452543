import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.scss";

import { RecoilRoot } from 'recoil';

const { REACT_APP_BACKEND_SERVER } = process.env;
console.log(REACT_APP_BACKEND_SERVER);

const NotFound = lazy(() => import("./views/front/placeholders"));

export default () => (

  <RecoilRoot>
    <Suspense fallback={<span>Cargando datos ...</span>}>
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
        <Switch>
          {routes.map((route, index) => {
           return (
               <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={withTracker(props => {
                    return (
                      <route.layout {...props}>
                        <route.component {...props} />
                      </route.layout>
                    );
                  })}
                />
            );
          })}
          <RouteWrapper component={NotFound} />
        </Switch>
      </Router>
    </Suspense>
  </RecoilRoot>
);

function RouteWrapper({
                        component: Component,
                        layout: Layout,
                        ...rest
                      }) {
  return (
    <Route {...rest} render={(props) =>
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    } />
  );
}

